<template>
  <div class="data-cont form join">
    <div class="join-header">卖场入驻</div>
    <div class="box shadow">
      <div class="form-group line">
        <div class="label ">省市区：</div>
        <div class="cont">
          <city ref="city" 
              :province="form.province"
              :city="form.city"
              :county="form.area"
              @change="upCity"></city>
        </div>
      </div>
      <div class="form-group line">
        <div class="label ">详细地址：</div>
        <div class="cont">
          <cube-input :maxlength="15" :clearable="clearable" v-model.trim="form.addr" placeholder="请填写卖场所在地"></cube-input>
        </div>
      </div>
      <div class="form-group line">
        <div class="label ">卖场名称：</div>
        <div class="cont">
          <cube-input :maxlength="15" :clearable="clearable" v-model.trim="form.stroeName" placeholder="请填写卖场或门店名称"></cube-input>
        </div>
      </div>
      <div class="form-group line">
        <div class="label ">是否为连锁：</div>
        <div class="cont">
          <div class="redio-cont" style="text-align: right;">
            <cube-checker
              v-model="form.isChain"
              :options="options3"
              type="radio" />
          </div>
        </div>
      </div>
      <div class="form-group line">
        <div class="label ">联系人姓名：</div>
        <div class="cont">
          <cube-input :maxlength="15" :clearable="clearable" v-model.trim="form.linkName" placeholder="请填写姓名"></cube-input>
        </div>
      </div>
      <div class="form-group line">
        <div class="label ">联系电话：</div>
        <div class="cont">
          <cube-input :maxlength="15" :clearable="clearable" v-model.trim="form.linkPhone" placeholder="请填写手机号"></cube-input>
        </div>
      </div>
      <div class="form-group line">
        <div class="label ">电子邮箱：</div>
        <div class="cont">
          <cube-input :maxlength="15" :clearable="clearable" v-model.trim="form.email" placeholder="请填写您的电子邮箱"></cube-input>
        </div>
      </div>
    </div>
    <div class="a-title">请您准确填写以上信息，我们的工作人员会在一个工作日内与您联系。</div>

    

    <div class="btns">
      <cube-button class="btn" @click="saveData">提交</cube-button>
    </div>


    <div class="modo" v-if="show">
      <div class="modo-in">
        <div class="modo-icon">
          <img src="@/assets/img/success.png" alt="">
        </div>
        <div class="modo-name">信息已经提交</div>
        <div class="modo-title">
          稍后客服会联系您，请耐心等待。
        </div>
        <div class="modo-btn" @click="goNext">确定</div>
      </div>
    </div>


  </div>
</template>

<script>
import city from '@/components/city.vue'
export default {
  name: 'dataText',
  components: {
    city
  },
  data () {
    return {
      options3:  [
        {
          text: '是',
          value: '1'
        },
        {
          text: '否',
          value: '2'
        }
      ],
      clearable: {
        visible: true,
        blurHidden: false,
        click: true,
      },
      form: {
        type: '1',
        "province":"",
        "city":"",
        "area":"",
        addr: '',
        stroeName: '',
        isChain: '2',
        linkName: '',
        linkPhone: '',
        email: '',
      },
      show: false,
      requireds: {
        addr: '请填写卖场所在地',
        stroeName: '请填写卖场或门店名称',
        linkName: '请填写姓名',
        linkPhone: '请填写联系电话',
        email: '请填写您的电子邮箱',
      },
    }
  },
  mounted () {
    
  },
  methods: {
    upCity (obj) {
      this.form.province = obj.province;
      this.form.city = obj.city;
      this.form.area = obj.county;
    },
    /**
     * 正整数(6位)
     */
    isNumber (str) {
      str += '';
      if(str.length > 6){
        return false;
      }
      if(! (/^[1-9]\d*$/.test(str))){
        return false
      }
      return true
    },
    /**
     * 更新图片
     */
    upFile (url, key) {
      this.form[key] = url;
    },
    /**
     * 保存数据
     */
    saveData () {
      const vm = this;
      let postData = {...this.form}
      for(let key in vm.requireds){
        if(this.form[key] == ''){
          this.$createDialog({
            type: 'alert',
            title: '',
            content: vm.requireds[key],
            icon: ''
          }).show()
          return false
        }
      }

      if(! this.$utils.isPhone(this.form.linkPhone)){
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '联系电话格式错误',
          icon: ''
        }).show()
        return
      }

      if(! this.$utils.isEmail(this.form.email)){
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '电子邮箱格式错误',
          icon: ''
        }).show()
        return
      }
      

      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/entry/storeEntry", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.show = true;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 下一步
     */
    goNext () {
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="scss" scoped>
.a-title{
  line-height: 36px;
  font-size: 24px;
  color: #333;
  padding: 0 50px;
}
.flex-cont{
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
}
.data-cont{
  padding: 30px 0 200px 0;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
}
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.form-group{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  .label{
    padding: 30px 0;
    &.required::before{
      content: "*";
      margin-right: 10px;
      color: #e64340;
    }
  }
  &.line{
    display: flex;
    .label{
      width: 180px;
    }
    .cont{
      flex: 10;
      text-align: left;
      &.text{
        padding: 30px 20px;
      }
      input{
        display: block;
        width: 100%;
        padding: 30px 0;
        height: 30px;
        border: none;
        outline: none;
        list-style: none;
      }
    }
  }
}
.on-text{
  color: #1b945e;
  line-height: 90px;
}
.select{
  display: flex;
  text-align: right;
  padding: 30px 0;
  .select-text{
    flex: 10;
  }
  .select-icon{
    padding-left: 18px;
    img{
      display: block;
      height: 30px;
      width: 18px;
    }
  }
}
.check-box{
  display: inline-block;
  box-sizing: border-box;
  height: 64px;
  width: 189px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  text-align: center;
  line-height: 64px;
  font-size: 28px;
  color: #CCCCCC;
  margin-bottom: 30px;
  margin-right: 30px;
  &.on{
    color: #1b945e;
    border-color: #1b945e;
  }
}
.form-group{
  .check-box:nth-child(3n){
    margin-right: 0;
  }
}
.textarea-out{
  padding-bottom: 30px;
}
.btns{
  width: 100%;
  padding: 40px 0 63px 0;
  position: absolute;
  bottom: 0;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #1b945e;
    border-radius: 50px;
    margin: 0 auto;
  }
}
.p-title{
  font-size: 26px;
  color: #999999;
  line-height: 37px;
  padding: 0 41px;
}
.redio-cont{
  padding-top: 14px;
  padding-left: 18px;
}
.join-header{
  font-size: 32px;
  padding: 0 40px 20px;
  color: #1b945e;
  font-weight: 700;
}
</style>